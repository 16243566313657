import { Link } from "@StarberryUtils";
import React,{useState, useEffect} from "react"
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
} from "react-bootstrap"
import SelectBox from "../../Home/SelectBox/SelectBox"

const ResaleForm = (props) => {

    const[form_values, setFormState] = React.useState(props.form_values);

    useEffect(()=>{
        setFormState(props.form_values);
    },[props.form_values])
  
  const minOptions = [
    { value: "100,000", label: "100,000", type: "dropdown", name: "budget_home_min"},
    { value: "200,000", label: "200,000", type: "dropdown", name: "budget_home_min" },
    { value: "300,000", label: "300,000", type: "dropdown", name: "budget_home_min" },
  ]
  const maxOptions = [
    { value: "400,000", label: "400,000", type: "dropdown", name: "budget_home_max" },
    { value: "600,000", label: "600,000", type: "dropdown", name: "budget_home_max" },
    { value: "800,000", label: "800,000", type: "dropdown", name: "budget_home_max" },
  ]

  var kind_of_permit = ["Apartment Building", "Hotel Permit", "Commercial & Retails Permit", "Residential Villa", "Public Building","Workshop/Factory"];
  var home_buying_journey = ["Just Started Looking at Listings", "Touring Properties", "Making or Made offers"];
  var decision_time = ["1-30 Days", "30-90 Days", "90-180 Days", "180-Above"];
  var buying_process = ["Yes", "No"];

  var journey_title = props.form_type === "land-plot" ? "Where are you in your Land-buying journey?" : "Where are you in your home-buying journey?";
  var journey_name = props.form_type === "land-plot" ? "land_buying_journey" : "home_buying_journey";
  var journey_values = props.form_type === "land-plot" ? form_values.land_buying_journey : form_values.home_buying_journey;

  //console.log("props", props)

  return (
    <>
        {
            props.form_type === "land-plot" ? <>
                <p className="question">
                    What Kind of permit you are looking to obtain?
                </p>
                <div className="q-option option-step2">
                    {
                        kind_of_permit.map((item,index) =>{
                            return(
                                <Form.Group>
                                    <Form.Check
                                    inline
                                    label={item}
                                    type="radio"
                                    name="kind_of_permit"
                                    value={item}
                                    onChange={props.handleChange}
                                    checked={item === form_values.kind_of_permit ? true : false}
                                    required={true}
                                    />
                                </Form.Group>
                            )
                        })
                    }
                </div>

                {
                    form_values.kind_of_permit && <>          
                    <p className="question">
                        {journey_title}            
                    </p>
                    <div className="q-option option-step2">
                        {
                            home_buying_journey.map((item,index) =>{
                                return(
                                    <Form.Group>
                                        <Form.Check
                                        inline
                                        label={item}
                                        type="radio"
                                        name={journey_name}
                                        value={item}
                                        onChange={props.handleChange}
                                        checked={item === journey_values ? true : false}
                                        required={true}
                                        />
                                    </Form.Group>
                                )
                            })
                        }
                    </div>
                    </>
                }
            </>
            : 
            <>
                <p className="question">
                    {journey_title}            
                </p>
                <div className="q-option option-step2">
                    {
                        home_buying_journey.map((item,index) =>{
                            return(
                                <Form.Group>
                                    <Form.Check
                                    inline
                                    label={item}
                                    type="radio"
                                    name={journey_name}
                                    value={item}
                                    onChange={props.handleChange}
                                    checked={item === journey_values ? true : false}
                                    required={true}
                                    />
                                </Form.Group>
                            )
                        })
                    }
                </div>
            </>
        }

        {
            journey_values && <>
            <p className="question">Are you a decision maker in this buying process?</p>
            <div className="q-option option-step3">
                {
                    buying_process.map((item,index) =>{
                        return(
                            <Form.Group>
                                <Form.Check
                                inline
                                label={item}
                                type="radio"
                                name="buying_process"
                                value={item}
                                onChange={props.handleChange}
                                checked={item === form_values.buying_process ? true : false}
                                required={true}
                                />
                            </Form.Group>
                        )
                    })
                }
            </div>
            </>
        }
        
        {
            form_values.buying_process && <>
                <p className="question">
                What is the budget that you have set to purchase a home?
                </p>
                <div className="q-option text-left">
                    <Form.Group>
                        <Form.Label>Minimum Price in AED</Form.Label>
                        {/* <SelectBox placeHolder="Select" name="budget_home_min" value={form_values.budget_home_min} options={minOptions} handleChange={props.handleChange} required={true}/> */}
                        <Form.Control
                            type="text"
                            placeholder="0"
                            onChange={props.handleChange}
                            value={form_values.budget_home_min}
                            name="budget_home_min"
                            required={true}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Maximum Price in AED</Form.Label>
                        {/* <SelectBox placeHolder="Select" name="budget_home_max" value={form_values.budget_home_max} options={maxOptions} handleChange={props.handleChange} required={true} /> */}
                        <Form.Control
                            type="text"
                            placeholder="0"
                            onChange={props.handleChange}
                            value={form_values.budget_home_max}
                            name="budget_home_max"
                            required={true}
                        />
                    </Form.Group>
                </div>
            </>
        }
        
        {
            (form_values.budget_home_min && form_values.budget_home_max) && <>
            <p className="question">What is your decision time frame?</p>
            <div className="q-option option-step4">
                {
                    decision_time.map((item,index) =>{
                        return(
                            <Form.Group>
                                <Form.Check
                                inline
                                label={item}
                                type="radio"
                                name="decision_time"
                                value={item}
                                onChange={props.handleChange}
                                checked={item === form_values.decision_time ? true : false}
                                required={true}
                                />
                            </Form.Group>
                        )
                    })
                }
            </div>  
            </>
        }      
    </>
              
  )
}

export default ResaleForm
