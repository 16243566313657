import { Link } from "@StarberryUtils";
import React,{useState, useEffect} from "react"
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
} from "react-bootstrap"
import SelectBox from "../../Home/SelectBox/SelectBox"

const ResaleForm = (props) => {

    const[form_values, setFormState] = React.useState(props.form_values);
    const [validated, setValidated] = useState(false);
    const [showerror, setShowerror] = useState(false);
    const myRef = React.createRef();

    const handleSubmit = (event) => {
        const form = event.currentTarget;
    
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
          setShowerror(true);
          setValidated(true);
          myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
        } else {
            event.preventDefault();
            setShowerror(false);
            setValidated(false);
            props.nextClick(form_values);
        }      
    }


    useEffect(()=>{
        setFormState(props.form_values);
    },[props.form_values])

    const minOptions = [
        { value: "100,000", label: "100,000", type: "dropdown", name: "budget_home_min"},
        { value: "200,000", label: "200,000", type: "dropdown", name: "budget_home_min" },
        { value: "300,000", label: "300,000", type: "dropdown", name: "budget_home_min" },
    ]
    const maxOptions = [
        { value: "400,000", label: "400,000", type: "dropdown", name: "budget_home_max" },
        { value: "600,000", label: "600,000", type: "dropdown", name: "budget_home_max" },
        { value: "800,000", label: "800,000", type: "dropdown", name: "budget_home_max" },
    ]
  
    var property_search_journey = ["Just Started looking at Listings", "Touring Properties", "Making or Made offers"];
    var decision_time = ["1-30 Days", "30-90 Days", "90-180 Days", "180-Above"];
    var buying_process = ["Yes", "No"];

    var type_business = [];
    props.prequal_qst && props.prequal_qst.Business_Type.length > 0 && props.prequal_qst.Business_Type.map(item => type_business.push(item.Business_Type));

    var established_business = ["Established Business", "Startup"];
    var commercial_registration = ["Yes", "No"];
    var industry_sector = ["Trades & Services", "Information & Communication Technology", "Healthcare and Medical", "Mining, Resources, and energy", "Hospitality & Tourism", "Others"];

  return (
    <>
    <div ref={myRef} />
    {showerror && <div className="alert alert-danger err_maxwdth">
    Highlighted(*) fields are required.
    </div>}
    <Form action="javascript:;" method="post" onSubmit={handleSubmit} noValidate validated={validated}>
        <p className="question">
            Are you an?
        </p>
        <div className="q-option option-step2">
            {
                established_business.map((item,index) =>{
                    return(
                        <Form.Group>
                            <Form.Check
                            inline
                            label={item}
                            type="radio"
                            name="established_business"
                            value={item}
                            onChange={props.handleChange}
                            checked={item === form_values.established_business ? true : false}
                            required={true}
                            />
                        </Form.Group>
                    )
                })
            }
        </div>
        
        {form_values.established_business && <>
            <p className="question">
                Do you have a valid  Commercial Registration?
            </p>
            <div className="q-option option-step3">
                {
                    commercial_registration.map((item,index) =>{
                        return(
                            <Form.Group>
                                <Form.Check
                                inline
                                label={item}
                                type="radio"
                                name="commercial_registration"
                                value={item}
                                onChange={props.handleChange}
                                checked={item === form_values.commercial_registration ? true : false}
                                required={true}
                                />
                            </Form.Group>
                        )
                    })
                }
            </div>
            </>
        }

        {form_values.commercial_registration && <>
            <p className="question">
                What Type of business are you?
            </p>
            <div className="q-option option-step2">
                {
                    type_business.map((item,index) =>{
                        return(
                            <Form.Group>
                                <Form.Check
                                inline
                                label={item}
                                type="radio"
                                name="type_business"
                                value={item}
                                onChange={props.handleChange}
                                checked={item === form_values.type_business ? true : false}
                                required={true}
                                />
                            </Form.Group>
                        )
                    })
                }
            </div>
        </>
        }

        {form_values.type_business && <>
            <p className="question">
                What industry sector are you in?
            </p>
            <div className="q-option option-step2">
                {
                    industry_sector.map((item,index) =>{
                        return(
                            <Form.Group>
                                <Form.Check
                                inline
                                label={item}
                                type="radio"
                                name="industry_sector"
                                value={item}
                                onChange={props.handleChange}
                                checked={item === form_values.industry_sector ? true : false}
                                required={true}
                                />
                            </Form.Group>
                        )
                    })
                }
            </div>
        </>
        }

        {form_values.industry_sector && <>
            <p className="question">
                How many employees do you need to accommodate in this commercial space?
            </p>
            <div className="q-option input-step">
                <Form.Group>
                    <Form.Control
                        type="text"
                        onChange={props.handleChange}
                        value={form_values.employee_accomodate}
                        name="employee_accomodate"
                        required={true}
                    />
                </Form.Group>
            </div>
        </>
        }

        {form_values.employee_accomodate && <>
            <p className="question">
                Where are you in your Property Search journey?
            </p>
            <div className="q-option option-step2">
                {
                    property_search_journey.map((item,index) =>{
                        return(
                            <Form.Group>
                                <Form.Check
                                inline
                                label={item}
                                type="radio"
                                name="property_search_journey"
                                value={item}
                                onChange={props.handleChange}
                                checked={item === form_values.property_search_journey ? true : false}
                                required={true}
                                />
                            </Form.Group>
                        )
                    })
                }
            </div>
        </>
        }
        
        {form_values.property_search_journey && <>
            <p className="question">
                Are you a decision maker in this process?
            </p>
            <div className="q-option option-step3">
                {
                    buying_process.map((item,index) =>{
                        return(
                            <Form.Group>
                                <Form.Check
                                inline
                                label={item}
                                type="radio"
                                name="buying_process"
                                value={item}
                                onChange={props.handleChange}
                                checked={item === form_values.buying_process ? true : false}
                                required={true}
                                />
                            </Form.Group>
                        )
                    })
                }
            </div>
        </>
        }

        {form_values.buying_process && <>
            <p className="question">
                What is the budget that you have set to lease a commercial space?
            </p>
            <div className="q-option text-left">
                <Form.Group>
                    <Form.Label>Minimum Price in AED</Form.Label>
                    {/* <SelectBox placeHolder="Select" name="budget_home_min" value={form_values.budget_home_min} options={minOptions} handleChange={props.handleChange} required={true}/> */}
                    <Form.Control
                        type="text"
                        placeholder="0"
                        onChange={props.handleChange}
                        value={form_values.budget_home_min}
                        name="budget_home_min"
                        required={true}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Maximum Price in AED</Form.Label>
                    {/* <SelectBox placeHolder="Select" name="budget_home_max" value={form_values.budget_home_max} options={maxOptions} handleChange={props.handleChange} required={true} /> */}
                    <Form.Control
                        type="text"
                        placeholder="0"
                        onChange={props.handleChange}
                        value={form_values.budget_home_max}
                        name="budget_home_max"
                        required={true}
                    />
                </Form.Group>
            </div>
        </>
        }

        {(form_values.budget_home_min && form_values.budget_home_max) && <>
            <p className="question">What is your decision time frame?</p>
            <div className="q-option option-step4">
                {
                    decision_time.map((item,index) =>{
                        return(
                            <Form.Group>
                                <Form.Check
                                inline
                                label={item}
                                type="radio"
                                name="decision_time"
                                value={item}
                                onChange={props.handleChange}
                                checked={item === form_values.decision_time ? true : false}
                                required={true}
                                />
                            </Form.Group>
                        )
                    })
                }
            </div> 
        </>
        }

        {form_values.decision_time && <>
            <div className="col-12">
                {/* <Button variant="primary" type="button"  onClick={()=>props.nextClick(form_values)}><span>Next Step</span></Button> */}
                <Button variant="primary" type="submit"><span>Next Step</span></Button>
            </div> 
        </>
        }     

    </Form>
    </>
              
  )
}

export default ResaleForm